
import { mapGetters } from "vuex";
// import * as types from "../vuex/types";
// import api from "../vuex/api/index";
import LazyYoutubeVideo from "vue-lazy-youtube-video/dist/vue-lazy-youtube-video.min.js";

import Hero from "./../components/Hero.vue";
// import Benefits from "./../components/Benefits.vue";
// import Testimonial from "./../components/Testimonial.vue";
// import HowWorks from "./../components/HowWorks.vue";
// import Trust from "./../components/Trust.vue";
import WorkingCapitalLandingPageFilter from "./../components/landingPageFilters/WorkingCapitalLandingPageFilter.vue";

// const Hero = () => import('./../components/Hero.vue');
const Benefits = () => import("./../components/Benefits.vue");
const Testimonial = () => import("./../components/Testimonial.vue");
const HowWorks = () => import("./../components/HowWorks.vue");
const Trust = () => import("./../components/Trust.vue");

export default {
  components: {
    WorkingCapitalLandingPageFilter,
    LazyYoutubeVideo,
    Benefits,
    Testimonial,
    HowWorks,
    Trust,
  },
  data() {
    return {
      toggle1: false,
      toggle2: false,
      imgHeight: "300px",
      carouselData: [
        {
          src: "/static/images/testimonial-1.jpg",
        },
        {
          src: "/static/images/testimonial-2.jpg",
        },
        {
          src: "/static/images/testimonial-3.jpg",
        },
        {
          src: "/static/images/testimonial-4.jpg",
        },
        {
          src: "/static/images/testimonial-5.jpg",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      // imgSrcPrefix: types.GET_IMAGE_SRC_PREFIX,
      // carousel: types.GET_INDEX_CAROUSEL,
      // products: types.GET_HOT_PRODUCTS,
      // userInfo: types.GET_USER,
    }),
  },
  methods: {
    gotoApply(item) {
      api
        .get("/mobile/item/loanItemFrontApply/mgxyIsCanApply.ht", {
          tokenId: this.userInfo.token,
          customerId: this.userInfo.crmCustomerId,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$router.push(`/applyLoan/${item.productId}/sbl`);
          } else if (res.data.code == 1) {
            this.$alert(res.data.msg);
          }
        });
    },
    setImgHeight() {
      const carouselItemImgList = document.getElementsByClassName(
        "index__carousel-item"
      );
      for (let i = 0; i < carouselItemImgList.length; i++) {
        this.imgHeight = `${carouselItemImgList[i].clientHeight - 3}px`;
      }
    },
    register() {
      this.$router.push({
        path: "/get-start",
      });
    },
    investerNew() {
      alert("ok");
      this.$router.push("/investerNew");
    },
  },

  mounted() {
  },
  head() {
    return {
      title: 'Equipment Financing Landing Page 1 | American Credit',
      meta : [
        {
          hid: 'description',
          name: 'description',
          content: 'Equip your business with American Credit\'s flexible and affordable equipment financing. Quick approval and funding. Rates from 8.99%. Apply now!'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'equipment financing, equipment loans, equipment-based loans, commercial equipment financing, business equipment loans, construction equipment loans, American Credit'
        }
      ]
    }
  }
};
